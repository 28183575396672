import React, { useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faUser, faGraduationCap, faBriefcase, faCog } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button className="toggle-button" onClick={handleToggle}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <ul className="sidebar-menu">
        <li className="sidebar-item">
          <FontAwesomeIcon icon={faHome} />
          <span className="sidebar-text">Home</span>
        </li>
        <li className="sidebar-item">
          <FontAwesomeIcon icon={faUser} />
          <span className="sidebar-text">About</span>
        </li>
        <li className="sidebar-item">
          <FontAwesomeIcon icon={faGraduationCap} />
          <span className="sidebar-text">Education</span>
        </li>
        <li className="sidebar-item">
          <FontAwesomeIcon icon={faBriefcase} />
          <span className="sidebar-text">Experience</span>
        </li>
        <li className="sidebar-item">
          <FontAwesomeIcon icon={faCog} />
          <span className="sidebar-text">Skills</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
