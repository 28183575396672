import logo from './logo.svg';
import './App.css';
import Sidebar from './Sidebar';
import Resume from './Resume';

function App() {
  return (
    <div className="App">
		  {/* <Sidebar /> */}
      <div className="main-content">
		    <Resume />
	    </div>
    </div>
  );
}

export default App;
