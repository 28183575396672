import React from 'react';
import './Resume.css';
import resumeData from './resumeData.json';
import headshot from './assets/jerod-headshot.png';
import churchlogo from './assets/church-logo.png';
import conservicelogo from './assets/conservice-logo.png';


const Resume = () => {
  const {
    name,
    email,
    phone,
    address,
    summary,
    education,
    experience,
    skills
  } = resumeData;

  return (
    <div className="container">
      <div className="header">
        <img src={headshot} alt={`${name} Headshot`} />
        <div>
          <h1>{name}</h1>
          <p>Email: {email}</p>
          <p>Phone: {phone}</p>
          <p>Address: {address}</p>
        </div>
      </div>
      <div className="section">
        <h2>Summary</h2>
        <p>{summary}</p>
      </div>
      <div className="section education">
        <h2>Education</h2>
        {education.map((edu, index) => (
          <div key={index}>
            <p><strong>{edu.degree}</strong></p>
            <p>{edu.university}, {edu.year}</p>
          </div>
        ))}
      </div>
      <div className="section experience">
        <h2>Professional Experience</h2>
        {experience.map((exp, index) => (
          <div key={index}>
            <image src={conservicelogo} />
            <p><strong>{exp.title}</strong></p>
            <p>{exp.location}, {exp.dates}</p>
            <p>{exp.description}</p>
          </div>
        ))}
      </div>
      <div className="section skills">
        <h2>Skills</h2>
        {skills.map((skill, index) => (
          <p key={index}>• {skill}</p>
        ))}
      </div>
    </div>
  );
}

export default Resume;
